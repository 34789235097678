<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': pelayananActive}">
        <a href="#" class="nav-link" @click="pelayananActive = !pelayananActive">
          <i class="nav-icon fas fa-laptop-medical"></i>
          <p>
            Pelayanan Klinik
            <!-- <i class="right fas fa-angle-left"></i> -->
          </p>
        </a>
        <ul class="nav nav-treeview">
          <li class="nav-item pl-2" v-if="props.akses.includes('resepsionis') || props.akses.includes('semua')">
            <router-link to="/resepsionis" class="nav-link">
              <i class="fas fa-concierge-bell nav-icon"></i>
              <p class="text-sm">Resepsionis</p>
            </router-link>
          </li>
          <li class="nav-item pl-2">
            <router-link to="/appointment-pasien" class="nav-link">
              <i class="fas fa-calendar-check nav-icon"></i>
              <p class="text-sm">Appointment Pasien</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('perawatan') || props.akses.includes('semua')">
            <router-link to="/perawatan-pemeriksaan-awal" class="nav-link">
              <i class="fas fa-user-nurse nav-icon"></i>
              <p class="text-sm">Pemeriksaan Awal</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('perawatan_dokter') || props.akses.includes('semua')">
            <router-link to="/perawatan-pemeriksaan-utama" class="nav-link">
              <i class="fas fa-user-md nav-icon"></i>
              <p class="text-sm">Pemeriksaan Utama</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('kunjungan_today') || props.akses.includes('semua')">
            <router-link to="/kunjungan-today" class="nav-link">
              <i class="fas fa-users nav-icon"></i>
              <p class="text-sm">Kunjungan Hari Ini</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('farmasi') || props.akses.includes('semua')">
            <router-link to="/farmasi" class="nav-link">
              <i class="fas fa-tablets nav-icon"></i>
              <p class="text-sm">Farmasi</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('farmasi') || props.akses.includes('semua')">
            <router-link to="/persediaan-farmasi" class="nav-link">
              <i class="fas fa-crutch nav-icon"></i>
              <p class="text-sm">Persediaan Farmasi</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('data_etiket') || props.akses.includes('semua')">
            <router-link to="/data_etiket" class="nav-link">
              <i class="fas fa-tablets nav-icon"></i>
              <p class="text-sm">Data Etiket</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('pio_farmasi') || props.akses.includes('semua')">
            <router-link to="/pio-farmasi" class="nav-link">
              <i class="fas fa-tablets nav-icon"></i>
              <p class="text-sm">Pio Farmasi</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('farmasi_penjualan_langsung') || props.akses.includes('semua')">
            <router-link to="/farmasi-penjualan-langsung" class="nav-link">
              <i class="fas fa-tablets nav-icon"></i>
              <p class="text-sm">Farmasi Penjualan Langsung</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('kasir') || props.akses.includes('semua')">
            <router-link to="/kasir" class="nav-link">
              <i class="fas fa-cash-register nav-icon"></i>
              <p class="text-sm">Kasir</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('pemeriksaan_lab') || props.akses.includes('semua')">
            <router-link to="/pemeriksaan-lab" class="nav-link">
              <i class="fas fa-microscope nav-icon"></i>
              <p class="text-sm">Pemeriksaan Laboratorium</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('data_rujukan_lab') || props.akses.includes('semua')">
            <router-link to="/data-rujukan-lab" class="nav-link">
              <i class="fas fa-microscope nav-icon"></i>
              <p class="text-sm">Data Rujukan Laboratorium</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('pembayaran_piutang') || props.akses.includes('semua')">
            <router-link to="/pembayaran-piutang" class="nav-link">
              <i class="fas fa-cash-register nav-icon"></i>
              <p class="text-sm">Pembayaran Piutang Pasien</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('data_transaksi_perawatan') || props.akses.includes('semua')">
            <router-link to="/transaksi-perawatan" class="nav-link">
              <i class="fas fa-file nav-icon"></i>
              <p class="text-sm">Data Transaksi Perawatan Pasien</p>
            </router-link>
          </li>
          <!-- <li class="nav-item pl-2" v-if="props.akses.includes('data_transaksi_perawatan') || props.akses.includes('semua')">
            <router-link to="/surat" class="nav-link">
              <i class="fas fa-file nav-icon"></i>
              <p class="text-sm">Data Surat</p>
            </router-link>
          </li> -->
          <!-- <li class="nav-item pl-2">
            <router-link to="/" class="nav-link">
              <i class="fas fa-desktop nav-icon"></i>
              <p class="text-sm">Konsultasi Online</p>
            </router-link>
          </li> -->
          <!-- <li class="nav-item pl-2">
            <router-link to="/satu-sehat" class="nav-link">
              <i class="fas fa-file nav-icon"></i>
              <p class="text-sm">Bundle Data Satu Sehat</p>
            </router-link>
          </li> -->
        
        </ul>
      </li>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const pelayananActive = ref(false)

const props = defineProps({
  akses: Array
})
</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>